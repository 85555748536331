<template>
    <section class="edit-category">
        <Loader :loading="showLoader" />
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title float-left">Edit Theme</h3>
                                <router-link class="float-right" to="/website-management/theme">
                                    <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="pt-2" @submit.prevent="addThemePage">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Title</label>
                                                <input v-model="addTheme.title" type="text" class="form-control"
                                                    :class="{
                                                        'is-invalid': submitted && $v.addTheme.title.$error,
                                                    }" />
                                                <div v-if="submitted && !$v.addTheme.title.required"
                                                    class="invalid-feedback">
                                                    Title is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Image</label>
                                                <vue-dropzone ref="myVueDropzone" id="thumbVideo "
                                                    :options="dropzoneOptions" :useCustomSlot="true">
                                                    <div class="dropzone-custom-title">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43"
                                                            viewBox="0 0 24 24" fill="transparent" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-upload"
                                                            color="">
                                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                            <polyline points="17 8 12 3 7 8"></polyline>
                                                            <line x1="12" y1="3" x2="12" y2="15"></line>
                                                        </svg>
                                                        <div class="subtitle">
                                                            <span> Drop File or </span>
                                                            <span style="color: #eb2027;"> Browse</span>
                                                        </div>
                                                    </div>
                                                </vue-dropzone>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group float-right mb-0 pt-3">
                                        <button class="btn btn btn-add btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import vue2Dropzone from "vue2-dropzone";
import { required } from "vuelidate/lib/validators";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Loader from "../../../components/Loader";
export default {
    name: "add-invoice",
    components: {
        Loader,
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            showLoader: false,
            addTheme: {
                title: "Shoplattice - E-Commerce Theme", 
            },
            options: [],
            dropzoneOptions: {
                url: `${mediaApiURL}/file/upload`,
                maxFilesize: 10,
            },
            submitted: false,
        };
    },
    validations: {
        addTheme: { 
            title: { required },
        },
    },
    mounted() { },
    methods: {

        async addThemePage() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.showLoader = true;
            console.log(this.addTheme);

            // const data = await setingsApi.addTheme({
            //     ...this.addTheme,
            // });
            this.showLoader = false;
            this.$router.push("/website-management/theme");
            if (data.status == 200) {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: " ",
                    text: data.messages,
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "Oops...",
                    text: data.messages,
                });
            }
        },
    },
};
</script>

<style scoped>
.dropzone {
    padding: 40px 20px !important;
    min-height: 175px !important;
}
</style>
